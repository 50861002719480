<template>
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 mt-5">
        <h1 class="text-left styled-title">:: Kontakt</h1>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row mt-4">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <h6 class="text-left styled-text">Prosimy o przesłanie danych kontaktowych. Skontaktujemy się z Państwem jak najszybciej to będzie możliwe. Pozdrawiamy!</h6>
      </div>
      <div class="col-md-2"></div>
    </div>

    <!-- FORM VALIDATION HANDLING -->
    <div class="row mt-4" v-if="formErrors.length > 0">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="alert alert-danger fade show" role="alert" v-for="error in formErrors" :key="error">
          {{ error }}
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>

    <!-- ERROR HANDLING -->
    <div class="row mt-4" v-if="formSubmitionStatus">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div v-if="formSubmitionStatus === 'IN-PROGRESS'" class="alert alert-success">Trwa wysyłanie wiadomości...</div>
        <div v-if="formSubmitionStatus === 'SUCCESS'" class="alert alert-success">Dziękujemy za wiadomość. Otrzymaliśmy ją i będziemy w kontakcie niedługo.</div>
        <div v-if="formSubmitionStatus === 'FAILURE'" class="alert alert-danger">Niestety, mieliśmy problem z wysyłką Państwa wiadomości. Prosimy spróbować później, lub wysłać e-mail bezpośrednio na <a :href="'mailto:' + companyEmailAddress" class="alert-link">{{ companyEmailAddress }}</a>.</div>
      </div>
      <div class="col-md-2"></div>
    </div>

    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 mt-5">

        <!-- CONTACT FORM -->
        <form>
          <div class="form-outline mb-4">
            <input type="text" class="form-control" placeholder="Twoje imię" required v-model="contactForm.customerName" id="imie" name="imie" />
          </div>

          <!-- Telephone input -->
          <div class="form-outline mb-4">
            <input type="tel" class="form-control" placeholder="Twój numer telefonu" v-model="contactForm.customerPhoneNumber" id="numerTelefonu" name="numerTelefonu" />
          </div>

          <!-- Email input -->
          <div class="form-outline mb-4">
            <input type="email" class="form-control" placeholder="Twój e-mail" v-model="contactForm.customerEmailAddress" id="email" name="email" />
          </div>

          <!-- Message input -->
          <div class="form-outline mb-4">
            <textarea class="form-control" rows="4" placeholder="Twoja wiadomość" required v-model="contactForm.messageBody"></textarea>
          </div>

          <!-- Submit button -->
          <button class="btn btn-secondary btn-block mb-4" 
                  type="button" @click="validateAndSubmitForm(contactForm)" :disabled="formSubmitionStatus === 'IN-PROGRESS'">Wyślij</button>
        </form>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col">
        <!-- Register buttons -->
        <!-- <div class="text-center">
          <p>or sign up with:</p>
          <button type="button" class="btn btn-primary btn-floating mx-1">
            <i class="fab fa-facebook-f"></i>
          </button>

          <button type="button" class="btn btn-primary btn-floating mx-1">
            <i class="fab fa-google"></i>
          </button>

          <button type="button" class="btn btn-primary btn-floating mx-1">
            <i class="fab fa-twitter"></i>
          </button>

          <button type="button" class="btn btn-primary btn-floating mx-1">
            <i class="fab fa-github"></i>
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContactUs',
  data() {
    return {
      companyEmailAddress: 'taxinaslub@gmail.com',
      formErrors: [],
      submitFormUrl: process.env.VUE_APP_API_GATEWAY_URL + '/email',
      contactForm: {
        customerName: undefined,
        customerPhoneNumber: undefined,
        customerEmailAddress: undefined,
        messageBody: undefined
      },
      formSubmitionStatus: undefined
    }
  },

  created() {
    this.clearForm();
  },

  methods: {
    clearForm() {
      this.contactForm = {
        customerName: undefined,
        customerPhoneNumber: undefined,
        customerEmailAddress: undefined,
        messageBody: undefined
      }
    },

    validateAndSubmitForm(form) {
      this.formSubmitionStatus = undefined;

      if (this.isContactFormValid()) {
        this.submitForm(form)
      }
    },

    submitForm() {
      this.formSubmitionStatus = 'IN-PROGRESS';

      axios.post(this.submitFormUrl, this.contactForm)
        // .then(() => {
        //   this.formSubmitionStatus = 'FAILURE';
        // })
        .then(response => {
          if (response && response.status && response.status === 200) {
            this.formSubmitionStatus = 'SUCCESS';
            this.clearForm();
          } else {
            this.formSubmitionStatus = 'FAILURE';
          }
        })
        .catch(() => {
          this.formSubmitionStatus = 'FAILURE';
        })
    },
    
    isContactFormValid() {
      this.formErrors = [];

      if (!this.validString(this.contactForm.customerName, 3)) {
        this.formErrors.push('Prosimy o podanie swojego imienia');
      }

      if (!this.validString(this.contactForm.customerEmailAddress, 5) && !this.validPhone(this.contactForm.customerPhoneNumber)) {
        this.formErrors.push('Prosimy o podanie telefonu lub address e-mail');
      }

      if (this.contactForm.customerEmailAddress && !this.validEmail(this.contactForm.customerEmailAddress)) {
        this.formErrors.push('Podany e-mail nie jest poprawny');
      }

      if (!this.contactForm.messageBody || !this.contactForm.messageBody.trim()) {
        this.formErrors.push('Prosimy o podanie treści wiadomości');
      }

      return this.formErrors.length === 0
    },

    validString(str, minLength) {
      return str && str.trim() && str.length >= minLength;
    },

    validPhone(phone) {
      return this.validString(phone, 9);
    },
    
    validEmail(email) {
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
    },

    // isContactFormValid(form) {
    //   return form && form.recipientName && form.messageText && (form.recipientPhoneNumber || form.recipientEmailAddress);
    // }
  }
}
</script>